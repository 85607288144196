<template>
    <header id="top-head" class="uk-position-fixed">
			<div class="uk-container uk-container-expand uk-background-primary">
				<nav class="uk-navbar uk-light" data-uk-navbar="mode:click; duration: 250">
					<div class="uk-navbar-left">
						<div class="uk-navbar-item uk-hidden@m">
							<router-link tag='a' to="/" class="custom-logo" title=""><img :src=" locale === 'ru' ? require('@/assets/logo.png') : require('@/assets/logo_us.png')"  style="width: 150px;" alt=""></router-link>
						</div>
					</div>
					<div class="uk-navbar-right">
						<ul class="uk-navbar-nav">
							 <li @click="changeLocale()">
								<a :style='{ backgroundImage: "url("+ require("@/assets/" + locale + ".png")+")"}' class="uk-icon uk-icon-image" :uk-tooltip="'title:' + localize('Language') +'; pos: bottom'"></a>
							</li>
                            <li class="uk-visible" :uk-tooltip="'title:' + localize('Home') +'; pos: bottom'">
                                <router-link tag='a' to="/search" data-uk-icon="icon: search"></router-link>
                            </li>
							<li><a class="uk-navbar-toggle uk-hidden@s" data-uk-toggle data-uk-navbar-toggle-icon href="#offcanvas-nav-mobiles" data-uk-tooltip></a></li>
						</ul>
					</div>
				</nav>
			</div>
		</header>
</template>

<script>
import localizeFilter from '@/filter/localize.filter'
import store from '@/store'
export default {
    data: () => ({
		locale: localStorage.getItem('locale') || 'ru',
    }),
    methods: {
        localize(key){
            return localizeFilter(key)
		},
		changeLocale(){
			/*
	        store.state.locale === 'us' ? (store.state.locale='ru', store.state.price = 'rub') : (store.state.locale = 'us', store.state.price = 'usd')
			
            this.locale === 'us' ? this.locale='ru': this.locale = 'us'
			localStorage.setItem('locale', this.locale)
			localStorage.setItem('price', store.state.price)
			*/
			store.state.locale === 'us' ? store.state.locale='ru' : store.state.locale = 'us'
			
            this.locale === 'us' ? this.locale='ru': this.locale = 'us'
			localStorage.setItem('locale', this.locale)
        }
    }
}
</script>