<template>
<div>
    <Header/>
    <Sidebar/>
    <div id="content" data-uk-height-viewport="expand: true">
        <div class="uk-container uk-container-expand">
            <button id="mobile-button" href="#toggle-animation" class="uk-hidden@s uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" type="button" uk-toggle="target: #toggle-animation; animation: uk-animation-fade">{{"Statistics" | localize}}</button>
            <div id="toggle-animation" class="uk-grid uk-grid-divider uk-grid-medium uk-child-width-1-2 uk-child-width-1-6@l uk-text-center" data-uk-grid >
                <div>
                    <span class="uk-text-small"><span data-uk-icon="icon:nut" class="uk-margin-small-right uk-text-primary"></span> {{"Catalytics" |localize}} </span>
                    <h2 class="uk-heading-primary uk-margin-remove  uk-text-primary">{{catalic}}</h2>
                </div>
                <div>
                    <span class="uk-text-small"><span data-uk-icon="icon:users" class="uk-margin-small-right uk-text-primary"></span>{{"Users" | localize}}</span>
                    <h2 class="uk-heading-primary uk-margin-remove uk-text-primary">{{users}}</h2>
                </div>
                <div>
                    <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary uk-icon uk-icon-image" :style='{ backgroundImage: "url("+ require("@/assets/dollar.png")+")"}' ></span>{{"Dollar" | localize}}</span>
                    <h2 class="uk-heading-primary uk-margin-remove uk-text-primary">{{usd | formatPrice}}</h2>
                </div>
                <div>
                    <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary uk-icon uk-icon-image" :style='{ backgroundImage: "url("+ require("@/assets/pl.png")+")"}' ></span>{{"Pt" | localize}}</span>
                    <h2 class="uk-heading-primary uk-margin-remove uk-text-primary">{{Pt | formatPrice}}</h2>
                </div>
                <div>
                    <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary uk-icon uk-icon-image" :style='{ backgroundImage: "url("+ require("@/assets/pd.png")+")"}' ></span>{{"Pd" | localize}}</span>
                    <h2 class="uk-heading-primary uk-margin-remove uk-text-primary">{{Pd | formatPrice}}</h2>
                </div>
                <div>
                    <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary uk-icon uk-icon-image" :style='{ backgroundImage: "url("+ require("@/assets/pd.png")+")"}' ></span>{{"Rh" | localize}}</span>
                    <h2 class="uk-heading-primary uk-margin-remove uk-text-primary">{{Rh |formatPrice}}</h2>
                </div>
            </div>
            <hr>
            <router-view/>
        </div>
    </div>
</div>
</template>

<script>
import Header from '@/components/dashboard/Header'
import Sidebar from '@/components/dashboard/Sidebar'
import store from '@/store'
import formatPrice from '@/filter/format.filter'
import localizeFilter from '@/filter/localize.filter'
export default {
    metaInfo:{
        title: localizeFilter('Dashboard')
    },
    name: 'dashboard-layout',
     data: () =>({
        usd: store.state.role == 5 || store.state.role === 6 ? store.state.defaud : store.state.defusd,
        Pt: store.state.defPt,
        Pd: store.state.defPd,
        Rh: store.state.defRh,
        catalic: store.state.catalic,
        users: store.state.users
    }),
    components:{
        Header, Sidebar
    },
    filters:{
        formatPrice
    },
    methods:{
    },
    beforeCreate(){
        this.$Progress.start()
        axios.get('/dashboard.php',
            {
                params:
                {
                    type: 1,
                }
            })
            .then(response => {
                if(response.data != -10){
                    this.$data.catalic = response.data['catalic'];
                    this.$data.users = response.data['users'];
                }else{
                    UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
                }
                this.$Progress.finish()
            })
            .catch(error =>{
                console.log(error)
                UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
                this.$Progress.fail()
            })
    },
    mounted(){
        //UIkit.toggle("#toggle-animation").toggle();
    }

}
</script>
