<template>
<div>
    <!--HEADER-->
    <header id="site-head">
        <Navbar/>
    </header>
    <!--/HEADER-->
    <div class="spacer">
    </div>
    <section class="uk-section uk-section-small " style="background-color: #f8f8f8;">
        <div class="uk-container uk-container-xlarge uk-margin-large-bottom">
            <div class="uk-grid-medium" uk-grid>
                <!-- FILTER -->
                    <mFilter/>
                <!-- /FILTER -->
                 <!-- PAGE -->
                <div class="uk-width-expand">
                    <router-view/>
                </div>
                 <!-- /PAGE -->
            </div>
        </div>
    </section>
    <!--FOOTER-->
    <Footer/>
    <!--/FOOTER -->
</div>
</template>


<script>
import Navbar from '@/components/app/Navbar'
import mFilter from '@/components/app/ResultFilter'
import Footer from '@/components/app/Footer'
import MobileNav from '@/components/app/MobileNav'
import MobileFilter from '@/components/app/MobileFilter'

export default {
    name: 'main-layout',
    components:{
        Navbar, mFilter, Footer, MobileNav, MobileFilter
    }
}
</script>