<template>
    <div>
        <div class="uk-margin">
            <label class="uk-form-label">{{"Mark" | localize}}</label>
            <select class="uk-select" uk-spinner v-model="selectedMark" v-on:change="getModel(selectedMark.value)">
                <option v-for="mark in marks" v-bind:value="mark">
                    {{ mark.text | trimBracket }}
                </option>
            </select>
        </div>
        <div class="uk-margin ">
            <label class="uk-form-label ">{{"Model" | localize}}</label>
            <select class="uk-select" v-model="selectedModel" v-on:change="getGeneration(selectedModel.value)">
                <option v-for="model in models" v-bind:value="model">
                    {{ model.text }}
                </option>
            </select>
        </div>
        <!--
        <div class="uk-margin ">
            <label class="uk-form-label ">{{"Generation" | localize}}</label>
            <select class="uk-select" v-model="selectedGeneration" >
                <option v-for="generation in generations" v-bind:value="generation">
                    {{ generation.text }}
                </option>
            </select>
        </div>
        <div class="uk-margin ">
            <label class="uk-form-label ">{{"Year" | localize}}</label>
            <input class="uk-input" type="text " :placeholder="localize('Year')" v-model='selectedYear'>
        </div>
        <div class="uk-margin ">
            <label class="uk-form-label ">{{"Country" | localize}}</label>
            <select class="uk-select" v-model="selectedCountry">

            <option v-for="country in countries" v-bind:value="country">
                {{ country.text | localize}}
            </option>

            <option v-for="country in countries" v-bind:value="country">
                {{country.text | localize}}
            </option>
            </select>
        </div>
        -->
        <!--
         <div class="uk-margin ">
            <label class="uk-form-label ">{{"EngineVolume" | localize}}</label>
            <div class="uk-margin-medium-top">
                <Slider v-model="engine" :step = "step" :min="min" :max="max"/>
            </div>
        </div>
        -->
        <button class="uk-button uk-button-primary uk-width-1-1" @click="gofilter()">{{"GoFilter" | localize}}</button>
        <button class="uk-button uk-button-default uk-margin-small-top uk-width-1-1" @click="clearFilter()">{{"Reset" | localize}}</button>
    </div>
</template>

<script>
import {eventBus} from '@/main'
import localizeFilter from '@/filter/localize.filter'
import Slider from '@vueform/slider/dist/slider.vue2.js'
import getModel from '@/config/GetModels.general'
import getMarks from '@/config/GetMarks.general'
import getGeneration from '@/config/GetGeneration.general'
import getCountries from '@/config/GetCountries.general'

export default {
    components: { Slider},
    data: () => ({
        mark: '',
        model: '',
        generation:'',
        country:'',
        marks: [],
        models: [],
        generations:[],
        countries: [],
        selectedMark: '',
        selectedModel: '',
        selectedGeneration: '',
        selectedYear: '',
        selectedCountry:'',
        allgeneration: [],
        allmarks: [],
        allmodels: [],
        allcountries: [],

        engine:[0, 10],
        step: -1,
        min:0,
        max:10
    }),
    name: 'Filters',
    methods:{
        getMarks,
        getModel,
        getGeneration,
        gofilter(){
            UIkit.offcanvas('#offcanvas-filter').hide();
            //if(this.selectedModel != ''|| this.selectedMark != '' || this.selectedGeneration != '' || this.selectedYear != '' || this.selectedCountry != ''){
                eventBus.$emit('gofilter', {
                    mark: this.selectedMark.text,
                    model: this.selectedModel.text,
                    generation: this.selectedGeneration.text,
                    year: this.selectedYear,
                    country: this.selectedCountry.text,
                   // engine: this.engine,
                })
           // }
        },
        clearFilter(){
            UIkit.offcanvas('#offcanvas-filter').hide();
            eventBus.$emit('clearfilter')
            this.selectedMark = ''
            this.selectedModel = ''
            this.selectedGeneration = ''
            this.selectedCountry = ''
            this.selectedYear = ''
            this.models = ''
            this.generations = ''
            //this.engine = [0,10]
        },
        getCountries,
        localize(key){
            return localizeFilter(key)
        }
    },
    beforeMount(){
        eventBus.$on('setFilter', data => {
            this.selectedMark = {value: data['mark'].value, text: data['mark'].text}
            this.getModel(data['mark'].value)
            //console.log(this.selectedMark)
        })
        this.getMarks()
        this.getCountries()

    }
}
</script>

<style src="@vueform/slider/themes/default.css"></style>
