<template>
  <div id="app">
    <noscript><div><img src="https://mc.yandex.ru/watch/88811961" style="position:absolute; left:-9999px;" alt="" /></div></noscript>
    <div>
      <!-- MOBILE FILTER -->
      <MobileFilter/>
      <!-- MOBIlE FILTER -->
      <!-- MOBILE NAV -->
      <MobileNav/>
      <MobileNavDashboard/>
      <!-- /MOBILE NAV -->
    </div>
    <component :is="layout">
      <router-view/>
    </component>
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<style>
  @import 'assets/uikit.min.css';
  @import 'assets/cards.css';
  @import 'assets/dashboard.css';
</style>

<script>
import EmptyLayout from '@/layouts/EmptyLayout'
import InsertLayout from '@/layouts/InsertLayout'
import MainLayout from '@/layouts/MainLayout'
import SearchLayout from '@/layouts/SearchLayout'
import DashboardLayout from '@/layouts/DashboardLayout'
import MobileNav from '@/components/app/MobileNav'
import MobileNavDashboard from '@/components/dashboard/MobileNavDashboard'
import MobileFilter from '@/components/app/MobileFilter'
import store from '@/store'
import localeFilter from '@/filter/localize.filter'
import formatPrice from '@/filter/format.filter'
import { metalSet, lmeSet } from 'vuex';

export default {
  computed:{
    layout(){
      return (this.$route.meta.layout || 'empty') + '-layout'
    }
  },
  components:{
    EmptyLayout, MainLayout, SearchLayout, MobileNav, MobileFilter, DashboardLayout, MobileNavDashboard, InsertLayout
  },
  created(){
    var tmp = JSON.parse(localStorage.getItem("cotirovki"))
    var date = tmp.date.split('-')
    store.state.defPt = tmp.defPt
    store.state.defPd = tmp.defPd
    store.state.defRh = tmp.defRh
    store.state.defusd = tmp.usd

    if(store.state.role == 5 || store.state.role == 6){
      if($cookies.get('aud') === null){
        axios({
          method: 'get',
          baseURL: '',
          //url: 'https://free.currconv.com/api/v7/convert?q=USD_AUD&compact=ultra&apiKey=6fa1ecf1571cb1366f17'
          url: 'https://openexchangerates.org/api/latest.json?app_id=76c1324c6345452c81b7a270939a68e4'
        })
        .then(response => {
          console.log(response.data.rates.AUD);
          store.state.defaud = response.data.rates.AUD.toFixed(2)
          $cookies.set('aud', store.state.defaud, '3h')
        })
        .catch(error => {
          store.state.defaud = 1.2967333.toFixed(2)
          console.log(error)
          UIkit.notification({message: 'Error while get USD-AUD exchange, please contact system administrator', status:'danger'})
        })
      }
      else{
        store.state.defaud = $cookies.get('aud')
      }
    }

    //store.commit('metalSet');
    this.$store.commit('metalSet');
    this.$store.commit('lmeSet');
    store.state.date = date[2] + '-' + date[1] + '-' + date[0].slice(-2)
    store.state.price = localStorage.getItem('price') || 'rub'
  }
}

</script>
