import localizeFilter from '@/filter/localize.filter'
export default function getGeneration(value){
    this.$Progress.start()
    axios.get('/filter.php', 
        {
            params: 
            {
                type: 3,
                selected: value
            }
        })
        .then(response => {
            if(response.data != -10){
                this.$data.generations = response.data;
                this.$data.allgenerations = response.data;
            }else
                UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
            this.$Progress.finish()
        })
        .catch(error =>{
            console.log(error)
            UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
            this.$Progress.fail()
        })
}