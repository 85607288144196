<template>
    <div>
        <div><label><input class="uk-checkbox" type="checkbox" :checked="userSettings" v-model="userSettings" @change = "setSetting('userSettings', userSettings == false ? 0 : 1)"> {{'userSettings' | localize}}</label></div>
        <h5 class="uk-margin-remove-adjacent">{{"Lme" | localize}}</h5>
        <hr>
        <!-- <div><label class="uk-form-label">LME: {{lme}} %</label> <input class="uk-range" type="range"  min="0" max="100" step="1" v-model="lme" @change = "setSetting('LME', lme)"></div> -->
        <div v-if="isMobile()"><label class="uk-form-label">PT: {{pt}}%</label> <input v-bind:disabled="!userSettings" class="uk-range" type="number"  min="0" max="100" step="1" v-model="pt" @change = "setSetting('Pt', pt)"></div>
        <div v-else><label class="uk-form-label">PT: {{pt}}%</label> <input v-bind:disabled="!userSettings" class="uk-range" type="range"  min="0" max="100" step="1" v-model="pt" @change = "setSetting('Pt', pt)"></div>

        <div v-if="isMobile()"><label class="uk-form-label">PD: {{pd}} %</label> <input v-bind:disabled="!userSettings" class="uk-range" type="number" min="0" max="100" step="1" v-model="pd" @change = "setSetting('Pd', pd)"></div>
        <div v-else><label class="uk-form-label">PD: {{pd}} %</label> <input v-bind:disabled="!userSettings" class="uk-range" type="range" min="0" max="100" step="1" v-model="pd" @change = "setSetting('Pd', pd)"></div>

        <div v-if="isMobile()"><label class="uk-form-label ">RH: {{rh}} %</label> <input v-bind:disabled="!userSettings" class="uk-range" type="number" min="0" max="100" step="1" v-model="rh" @change = "setSetting('Rh', rh)"></div>
        <div v-else><label class="uk-form-label ">RH: {{rh}} %</label> <input v-bind:disabled="!userSettings" class="uk-range" type="range" min="0" max="100" step="1" v-model="rh" @change = "setSetting('Rh', rh)"></div>

        <h5 class="uk-margin-remove-adjacent">{{"Rates" | localize}}</h5>
        <hr>
        <div v-if="this.$data.role != '5'"><label class="uk-form-label">USD </label> <input v-bind:disabled="!userSettings" class="uk-input uk-margin-small-left uk-form-blank uk-form-small uk-text-center" type="number" step="0.01" v-model="usd" @change = "setSetting('usd', usd)" style="width: 100px;"></div>
        <div v-if="this.$data.role === '5'"><label class="uk-form-label">AUD </label> <input v-bind:disabled="!userSettings" class="uk-input uk-margin-small-left uk-form-blank uk-form-small uk-text-center" type="number" step="0.01" v-model="aud" @change = "setSetting('aud', aud)" style="width: 100px;"></div>
        <h5 class="uk-margin-remove-adjacent">{{"Price" | localize}}</h5>
        <hr>
        <div class="uk-margin uk-margin-left uk-grid-small uk-child-width-auto uk-grid">
            <label v-if="this.$data.role != '5'"><input class="uk-radio" type="radio" name="radio2" value="rub" :checked="price === 'rub'" v-model="price" @change = "setSetting('price', price)"> ₽ </label>
            <label v-if="this.$data.role != '5'"><input class="uk-radio" type="radio" name="radio2" value="usd" :checked="price === 'usd'" v-model="price" @change = "setSetting('price', price)"> $ </label>
            <label v-if="this.$data.role === '5'"><input class="uk-radio" type="radio" name="radio2" value="usd" :checked="price === 'usd'" v-model="price" @change = "setSetting('price', price)"> $ </label>
            <label v-if="this.$data.role === '5'"><input class="uk-radio" type="radio" name="radio2" value="rub" :checked="price === 'rub'" v-model="price" @change = "setSetting('price', price)"> A$ </label>
        </div>
    </div>
</template>

<script>
import store from '@/store'
import localizeFilter from '@/filter/localize.filter'
export default {

    data: () => ({
       // lme: localStorage.getItem('LME'),
        pt: localStorage.getItem('Pt'),
        pd: localStorage.getItem('Pd'),
        rh: localStorage.getItem('Rh'),
        usd: localStorage.getItem('usd'),
        aud: localStorage.getItem('aud'),
        price: localStorage.getItem('price'),
        role: $cookies.get('role'),
        userSettings: JSON.parse(localStorage.getItem('userSettings'))

    }),
    methods: {
        setSetting(what, value){
            if(this.userSettings){
                this.$Progress.start()
                axios.get('/users.php',
                {
                    params:
                    {
                        type: 3,
                        what: what,
                        value: value,
                        login: localStorage.getItem('login')
                    }
                })
                .then(response => {
                    if(response.data != -10){
                        localStorage.setItem(what, value)
                        store.state.lmePt = this.pt
                        store.state.lmePd = this.pd
                        store.state.lmeRh = this.rh
                        store.state.usd = this.usd
                        store.state.aud = this.aud
                        store.state.price = this.price
                        //value = store.state.role === '5' ? 'aud' : 'usd'
                        UIkit.notification({message: 'Значение ' + what + ': ' + value + ' сохранено в профиле', status:'success', pos: 'bottom-right'})
                    }else{
                        UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
                    }
                    this.$Progress.finish()
                })
                .catch(error =>{
                    console.log(error)
                    UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
                    this.$Progress.fail()
                })
            }
            else if(what === 'price'){
                localStorage.setItem('price', this.price)
                store.state.price = this.price
                UIkit.notification({message: 'Значение ' + what + ': ' + value + ' сохранено в профиле', status:'success', pos: 'bottom-right'})
            }
            else{

                store.state.lmePt =70
                store.state.lmePd = 65
                store.state.lmeRh = 60
                store.state.usd = store.state.defusd
                store.state.aud = store.state.defaud

                this.pt = 70
                this.pd = 65
                this.rh = 60
                this.usd = store.state.defusd
                this.aud = store.state.defaud

                localStorage.setItem('Pt', 70)
                localStorage.setItem('Pd', 65)
                localStorage.setItem('Rh', 60)
                localStorage.setItem('usd', store.state.defusd)
                localStorage.setItem('aud', store.state.defaud)
                localStorage.setItem('userSettings', false)

                UIkit.notification({message: 'Значения установлены по умолчанию', status:'success', pos: 'bottom-right'})
            }

        },

        isMobile(){
            if(/Android|WebOS|iPhone|iPad|iPod|Blackberry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
                return true;
            }else{
                return false;
            }
        }
    }
}
</script>
