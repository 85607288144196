import localizeFilter from '@/filter/localize.filter'
export default function getCountries(){
    this.$Progress.start()
    axios.get('/filter.php', 
        {
            params: 
            {
                type: 4,
            }
        })
        .then(response => {
            if(response.data != -10){
                this.$data.countries = response.data;
                this.$data.allcountries = response.data;
            }else{
                UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
            }
            this.$Progress.finish()
        })
        .catch(error =>{
            console.log(error)
            UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
            this.$Progress.fail()
        })
}