import ru from '@/locales/ru.json'
import en from '@/locales/en.json'
import store from '@/store'

const locales = {
    'ru' : ru,
    'us' : en
}
export default function localeFilter(key){
    //const locale = localStorage.getItem('locale') !='' ? localStorage.getItem('locale') : 'ru';
    const locale = store.state.locale;
    return locales[locale][key] || `[localize error] : key ${key} not found`
}