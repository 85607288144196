<template>
    <div id="offcanvas-filter" data-uk-offcanvas="flip: true; overlay: true container: #app">
        <div class="uk-offcanvas-bar uk-offcanvas-bar-animation uk-offcanvas-slide ">
            <button class="uk-offcanvas-close uk-close uk-icon " type="button " data-uk-close></button>
            <h5 class="uk-margin-remove-adjacent uk-margin-small-top ">{{"Filter" | localize}}</h5>
            <hr>
            <sFilter/>
            <hr class="uk-divider-icon">
            <userSettings v-if="['1','3','4','5'].includes(this.$data.role)"/>
        </div>
    </div>
</template>

<script>
import sFilter from './Filter.vue'
import userSettings from './UserSettings'
export default {
    data: () => ({
        role: $cookies.get('role')
    }),
     components:{
          sFilter, userSettings
    }
}
</script>