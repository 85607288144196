import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    locale: localStorage.getItem('locale') == null ? 'ru' : localStorage.getItem('locale'),
    login:  '',
    Pt: '',
    Pd: '',
    Rh: '',
    price: 'rub',
    role: '',
    loggedIn: false,
    email: '',
    userSettings: 0,
    lmePt: 70,
    lmePd: 65,
    lmeRh: 60,
    defPt: '',  // price
    defPd: '',  // price
    defRh: '',  // price
    defusd:'',
    defaud:'',
    date: '',
    usd: localStorage.getItem('usd'),
    aud: localStorage.getItem('aud'),
    users: '',
    catalic: '',
    favourite:'',
    mr : '',
    mi : '',
    mb : ''
  },
  mutations: {
    metalSet (state){
 // Get METAL PRICE
          axios(
            {
              method: 'get',
              url: 'https://autocatcatalog.com/php/dashboard.php?type=30',
             //url: 'http://autocat.ru/php/dashboard.php?type=30',
            })
            .then(response => {
                if(response.data.lenght != 0 || response.data != -10){
                  state.mr = response.data[0].russia
                  state.mi = response.data[0].import
                  state.mb = response.data[0].bmw
                }else
                    UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
            })
            .catch(error =>{
                console.log(error)
                UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
            })

    },
    lmeSet (state){
 // Get METAL PRICE
          axios(
            {
              method: 'get',
              url: 'https://autocatcatalog.com/php/dashboard.php?type=32',
              //url: 'http://autocat.ru/php/dashboard.php?type=32',
              params:{
                login: localStorage.getItem('login')
              }

            })
            .then(response => {
                if(response.data.lenght != 0 || response.data != -10){

                  state.lmePt = Number(response.data[0].Pt)
                  state.lmePd = Number(response.data[0].Pd)
                  state.lmeRh = Number(response.data[0].Rh)
                }else
                    UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
            })
            .catch(error =>{
                console.log(error)
                UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
            })

    }
  },
  actions: {
  },
  modules: {
  }
})
