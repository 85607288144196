<template>
     <footer id="site-foot" class="uk-section uk-section-secondary uk-section-xsmall">
        <div class="uk-container uk-text-small uk-text-center">
            <span class="uk-text-emphasis"> {{symbol}} {{usd}} | Pt {{Pt}} | Pd {{Pd}} | Rh {{Rh}} | {{date}} </span>
        </div>
    </footer>
</template>

<script>
import store from '@/store'
export default {
    data: () =>({
        usd: store.state.role === '5' || store.state.role === '6' ? store.state.defaud : store.state.defusd,
        Pt: store.state.defPt,
        Pd: store.state.defPd,
        Rh: store.state.defRh,
        date: store.state.date,
        symbol: '$'
    })
}
</script>
