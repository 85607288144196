<template>
    <div class="uk-width-1-4 uk-visible@m">
        <div class="uk-card-default uk-margin uk-card-body" uk-sticky="top: #site-head; offset: 100">
            <div class="uk-child-width-1-2" uk-grid>
                <span class="uk-text-emphasis "> {{"Filter" | localize}} </span>
                <ul class="uk-iconnav uk-flex-right ">
                    <li>
                        <a id="favourite" data-uk-icon="icon: star" :style="style" :uk-tooltip="'title:'+ localize('Favourite') +'; pos: bottom '" @click="filterFavourite()"></a>
                    </li>
                    <li>
                        <a id="show-list" data-uk-icon="icon: table" :uk-tooltip="'title:'+ localize('List') +'; pos: bottom '" @click="change(true)"></a>
                    </li>
                    <li>
                        <a id="show-grid" data-uk-icon="icon: grid" :uk-tooltip="'title:'+ localize('Grid') +'; pos: bottom '" @click="change(false)"></a>
                    </li>
                </ul>
            </div>
            <hr>
            <sFilter/>
        </div>
    </div>
</template>

<script>
import {eventBus} from '@/main'
import sFilter from './Filter.vue'
import store from '@/store'
import localizeFilter from '@/filter/localize.filter'
export default {
    data: () => ({
        flag: true,
        style: ''
    }),
    components:{
          sFilter
    },
    methods:{
        localize(key){
            return localizeFilter(key);
        },
        change(value){
            if(value){
                eventBus.$emit('list', {
                    list: true
                })
            }
            else{
                eventBus.$emit('grid', {
                    list: false
                })
            }
        },
        filterFavourite(){
            if(this.flag){
                this.flag = false
                this.style = 'color:#FFCC33'
                eventBus.$emit('favourite', {
                    favourite: $cookies.get("applicationRevId")
                })
            }
            else{
                this.flag = true
                this.style = ''
                eventBus.$emit('favourite', {
                    favourite: ''
                })
            }

        }
    }
}
</script>
