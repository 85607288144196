<template>
		<aside id="left-col" class="uk-light uk-visible@m">
			<div class="left-logo">
				<router-link tag='a' to="/"  title=""><img src="@/assets/logo.png" alt=""></router-link>
			</div>
			<div class="left-nav-wrap">
				<ul class="uk-nav uk-nav-default uk-nav-parent-icon" data-uk-nav>
					<li class="uk-nav-header">{{"Navigation" | localize}}</li>
					<li>
						<router-link tag='a' to="/dashboard" active-class="clicked-menu" exact>
							<span data-uk-icon="icon: home" class="uk-margin-small-right"></span>{{localizeFilter('Home')}}
						</router-link>
					</li>
					<li>
						<router-link tag='a' to="/dashboard/catalytic" active-class="clicked-menu">
							<span data-uk-icon="icon: nut" class="uk-margin-small-right"></span>{{localizeFilter('Catalytics')}}
						</router-link>
					</li>
					<li>
						<router-link tag='a' to="/dashboard/users" active-class="clicked-menu" :exact="true">
							<span data-uk-icon="icon: users" class="uk-margin-small-right"></span>{{localizeFilter('Users')}}
						</router-link>
					</li>
					<li>
						<router-link tag='a' to="/dashboard/insert" active-class="clicked-menu">
							<span data-uk-icon="icon: plus-circle" class="uk-margin-small-right"></span>{{localizeFilter('New_cat')}}
						</router-link>
					</li>
					<li>
						<router-link tag='a' to="/dashboard/newuser" active-class="clicked-menu">
							<span data-uk-icon="icon: user" class="uk-margin-small-right"></span>{{localizeFilter('NewUser')}}
						</router-link>
					</li>
					<li>
						<router-link tag='a' to="/dashboard/marks" active-class="clicked-menu">
							<span data-uk-icon="icon: list" class="uk-margin-small-right"></span>{{localizeFilter('Marks')}}
						</router-link>
					</li>
					<li>
						<router-link tag='a' to="/dashboard/models" active-class="clicked-menu">
							<span data-uk-icon="icon: list" class="uk-margin-small-right"></span>{{localizeFilter('Models')}}
						</router-link>
					</li>
					<li>
						<router-link tag='a' to="/dashboard/generations" active-class="clicked-menu">
							<span data-uk-icon="icon: list" class="uk-margin-small-right"></span>{{localizeFilter('Generations')}}
						</router-link>
					</li>
					<li>
						<router-link tag='a' to="/dashboard/countries" active-class="clicked-menu">
							<span data-uk-icon="icon: list" class="uk-margin-small-right"></span>{{localizeFilter('Countries')}}
						</router-link>
					</li>
					<li>
						<router-link tag='a' to="/dashboard/filials" active-class="clicked-menu">
							<span data-uk-icon="icon: list" class="uk-margin-small-right"></span>{{localizeFilter('Filials')}}
						</router-link>
					</li>
					<li>
						<router-link tag='a' to="/dashboard/journal" active-class="clicked-menu">
							<span data-uk-icon="icon: database" class="uk-margin-small-right"></span>{{localizeFilter('Journal')}}
						</router-link>
					</li>
					<li>
						<router-link tag='a' to="/dashboard/audcurrencies" active-class="clicked-menu">
							<span data-uk-icon="icon: cart" class="uk-margin-small-right"></span>{{localizeFilter('AudCurs')}}
						</router-link>
					</li>
					<li>
						<router-link tag='a' to="/dashboard/metalrates" active-class="clicked-menu">
							<span data-uk-icon="icon: credit-card" class="uk-margin-small-right"></span>{{localizeFilter('MetalRates')}}
						</router-link>
					</li>
<!--
					<li @click="logout()">
						<a>
							<span class="uk-margin-small-right uk-icon" data-uk-icon="icon: sign-out"></span> {{localizeFilter("Logout")}}
						</a>
					</li>
-->
				</ul>
			</div>
		</aside>
</template>

<script>
import localizeFilter from '@/filter/localize.filter'
import store from '@/store'
export default {
	data: () => ({
		clicked: ''
	}),
	methods:{
		localizeFilter,
		logout(){
            localStorage.removeItem('Pt')
            localStorage.removeItem('Pd')
            localStorage.removeItem('Rh')
            localStorage.removeItem('price')
            localStorage.removeItem('usd')
            localStorage.removeItem('aud')
            localStorage.removeItem('cotirovki')
            localStorage.removeItem('userSettings')
            localStorage.removeItem('login')

            store.state.loggedIn = ''
            store.state.login = ''
            store.state.userSettings =  ''
            store.state.Pt = ''
            store.state.Pd =  ''
            store.state.Rh =  ''
            store.state.price =  ''
            store.state.role =  ''
            store.state.email =  ''
            store.state.usd =  ''
            store.state.aud =  ''

            this.$cookies.remove("loggedIn")
            this.$cookies.remove("role")
            this.$cookies.remove("changed")

            this.$router.push('/')
        }
	}
}
</script>
