import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import axios from 'axios'
import localeFilter from '@/filter/localize.filter'
import trimBracket from '@/filter/trimBracket.filter'
import VueMeta from 'vue-meta'
import JsonExcel from 'vue-json-excel'


//FULL SCREEN LOADER
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
// Init plugin
Vue.use(Loading);

// TOP LOADER
import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
  color: 'rgb(153, 255, 153)',
  failedColor: 'red',
  height: '12px'
})

// nevedoma xyinya
import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)

//Generate EXCEL files
Vue.component('downloadExcel', JsonExcel)

// FILTERS
Vue.filter('localize', localeFilter)
Vue.filter('trimBracket', trimBracket)

// EVENT BUS GLOBAL
export const eventBus = new Vue()

// AXIOS SETTINGS
const debug = process.env.NODE_ENV !== 'production';

    (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
    m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

    ym(88811961, "init", {
	    clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
   });
// Для dev-режима
if (debug) {
    axios.defaults.baseURL = 'http://autocat.ru/php/';
    //axios.defaults.baseURL = 'http://192.168.88.122/';
}
else{
    //test at home
    //axios.defaults.baseURL = 'http://192.168.31.60/php/'

    //production
    axios.defaults.baseURL = 'https://autocatcatalog.com/php/';
    //<!-- Yandex.Metrika counter -->

}

window.axios = axios

//COOKIES
Vue.use(require('vue-cookies'))

// UIKIT SETTINGS
UIkit.use(Icons)
window.UIkit = UIkit

// MEATA and TITLES
Vue.use(VueMeta)

Vue.config.productionTip = false

authJWT().then(router.beforeEach((to, from, next) => {
    store.state.loggedIn = Vue.$cookies.get("loggedIn")
    store.state.role = Vue.$cookies.get("role")
    if(to.matched.some(record => record.meta.loggedIn)) {
        if (!store.state.loggedIn) {
            next({ name: 'login'})
        } else {
              let role = store.state.role
              if(to.matched.some(record => record.meta.roleTable)) { // Общая таблица
                  if(role == 1 || role == 3 || role == 4 || role == 5 || role == 6 ){
                      next()
                  }
                  else{
                      UIkit.notification({message: localeFilter("AccessDenied"), status:'error'})
                      next({ name: 'search form'})
                  }
              }else if(to.matched.some(record => record.meta.roleInsert)) { // ВВод
                  if(role == 1 || role == 4){
                      next()
                  }
                  else{
                      UIkit.notification({message: localeFilter("AccessDenied"), status:'error'})
                      next({ name: 'search form'})
                  }
              }else if(to.matched.some(record => record.meta.admin)) { // Панель админа
                  if(role == 1){
                      next()
                  }
                  else{
                      UIkit.notification({message: localeFilter("AccessDenied"), status:'error'})
                      next({ name: 'search form'})
                  }
              }else if(to.matched.some(record => record.meta.roleAus)) { // курсавстралии отдельной страницей
                  if(role == 1 || role == 5){
                      next()
                  }
                  else{
                      UIkit.notification({message: localeFilter("AccessDenied"), status:'error'})
                      next({ name: 'search form'})
                  }
              }else {
                  next()
              }
        }
    }else {
      next()
    }
})
)

function authJWT(){
    let login = localStorage.getItem("login") || ''
    let role = Vue.$cookies.get("role") || ''
    let loader = Vue.$loading.show({
        canCancel: false,
        loader: 'dots'
  });
    return axios(
    {
        method: 'get',
        url:'https://autocatcatalog.com/php/check.php',
        //url:'http://autocat.ru/php/check.php',
        params:
        {
            login: login,
            role: role,
            type: 1
        }
    })
    .then(response => {
        if(response.data.answer == -1 || response.data.answer == -11 || response.data.answer == -10 || response.data.answer == -3){
            localStorage.removeItem('login')
            //localStorage.removeItem('LME')
            localStorage.removeItem('Pt')
            localStorage.removeItem('Pd')
            localStorage.removeItem('Rh')
            localStorage.removeItem('aud')
            localStorage.removeItem('usd')
            localStorage.removeItem('price')
            Vue.$cookies.remove("loggedIn")
            Vue.$cookies.remove("role")
            Vue.$cookies.remove("changed")
            loader.hide()
            new Vue({
                router,
                store,
                render: h => h(App)
              }).$mount('#app')
        }
        else{
            // ТODO: в файле check.php проверять роль пользователя и записывать из базы значениия котровок для австралии отдельно
            Vue.$cookies.set("loggedIn", true, Infinity)
            Vue.$cookies.set("role", response.data.answer, Infinity)
            Vue.$cookies.set("applicationRevId", response.data.applicationRevId, '1day') // id for favourite cat
            if(role === '5' || role === '6'){
                if(response.data.cotirovki['Pt'] != null || response.data.cotirovki['Pd'] != null || response.data.cotirovki['Rh'] != null){

                    var cotirovki = {
                        'defPt' : response.data.cotirovki['Pt'],
                        'defPd' : response.data.cotirovki['Pd'],
                        'defRh' : response.data.cotirovki['Rh'],
                        'usd' : response.data.cotirovki['usd'],
                        'date' : response.data.cotirovki['date']
                    }
                }
                else{
                    console.log('Не установлены котировки металлов для региона: Австралия?, обраитесь к администратору')
                    UIkit.notification({message: localeFilter("AudNotSet"), status:'danger'})
/*
                    var cotirovki = {
                        'defPt' : response.data.cotirovki['Pt_aud'],
                        'defPd' : response.data.cotirovki['Pd_aud'],
                        'defRh' : response.data.cotirovki['Rh_aud'],
                        'usd' : response.data.cotirovki['usd'],
                        'date' : response.data.cotirovki['date']
                    }
 */
                }
            }else{
                var cotirovki = {
                    'defPt' : response.data.cotirovki['Pt'],
                    'defPd' : response.data.cotirovki['Pd'],
                    'defRh' : response.data.cotirovki['Rh'],
                    'usd' : response.data.cotirovki['usd'],
                    'date' : response.data.cotirovki['date']
                }
            }
            localStorage.setItem('cotirovki', JSON.stringify(cotirovki))
            loader.hide()
            new Vue({
                router,
                store,
                render: h => h(App)
              }).$mount('#app')
        }
    })
    .catch(error =>{
        console.log(error)
        UIkit.notification({message: localeFilter('Fatal_error_axios'), status:'danger'})
    })
}

