<template>
    <div id="offcanvas-nav" data-uk-offcanvas="flip: true; overlay: true container: #app">
        <div class="uk-offcanvas-bar uk-offcanvas-bar-animation uk-offcanvas-slide">
            <button class="uk-offcanvas-close uk-close uk-icon" type="button" data-uk-close ref="drop"></button>
            <ul @click="mnavcl" class="uk-nav uk-nav-default ">
                <li class="uk-nav-header uk-margin-small-top ">{{"Navigation" | localize}}</li>
                <li><router-link tag='a' to="search" active-class="clicked-menu" :exact="true"><span class="uk-margin-small-right uk-icon" data-uk-icon="icon: home"></span> {{"Home" | localize}} </router-link></li>
                <li v-if="['1','4'].includes(this.$data.role)"><router-link tag='a' to="insert" active-class="clicked-menu"><span class="uk-margin-small-right uk-icon" data-uk-icon="icon: plus"></span> {{"New_cat" | localize}} </router-link></li>
                <li v-if="['1','4','5'].includes(this.$data.role)"><router-link tag='a' to="table" active-class="clicked-menu"><span class="uk-margin-small-right uk-icon" data-uk-icon="icon: album"></span> {{"Full_catalog" | localize}}</router-link></li>
                <li v-if="['1','4','5'].includes(this.$data.role)"><router-link tag='a' to="average" active-class="clicked-menu"><span class="uk-margin-small-right uk-icon" data-uk-icon="icon: cart"></span> {{"TitleAverage" | localize}}</router-link></li>
                <li> <router-link tag='a' to="average-m" active-class="clicked-menu"><span class="uk-margin-small-right uk-icon" data-uk-icon="icon: bolt"></span> {{"TitleAverageM" | localize}}</router-link></li>
                <li v-if="this.$data.role === '5'"><router-link tag='a' to="aus" active-class="clicked-menu"><span class="uk-margin-small-right uk-icon" data-uk-icon="icon: credit-card"></span> {{"AudChange" | localize}} </router-link></li>
                <li class="uk-nav-divider "></li>
                <li v-if="this.$data.role === '1'"><router-link tag='a' to="dashboard" active-class="clicked-menu"><span class="uk-margin-small-right uk-icon" data-uk-icon="icon: laptop"></span> {{"Dashboard" | localize}} </router-link></li>
<!--
                <li @click="logout()"><a><span class="uk-margin-small-right uk-icon" data-uk-icon="icon: sign-out"></span> {{"Logout" | localize}} </a></li>
-->
                <li><a href="/faq.pdf"><span class="uk-margin-small-right uk-icon" data-uk-icon="icon: question"></span> {{"FAQ" | localize}}</a></li>
            </ul>
        </div>
    </div>
</template>


<script>
import store from '@/store'
export default {
    data: () => ({
        role: $cookies.get('role')
    }),
    methods:{
        mnavcl(){
            UIkit.offcanvas('#offcanvas-nav').hide();
        },
        logout(){
            localStorage.removeItem('Pt')
            localStorage.removeItem('Pd')
            localStorage.removeItem('Rh')
            localStorage.removeItem('price')
            localStorage.removeItem('usd')
            localStorage.removeItem('aud')
            localStorage.removeItem('cotirovki')
            localStorage.removeItem('userSettings')
            localStorage.removeItem('login')

            store.state.loggedIn = ''
            store.state.login = ''
            store.state.userSettings =  ''
            store.state.Pt = ''
            store.state.Pd =  ''
            store.state.Rh =  ''
            store.state.price =  ''
            store.state.role =  ''
            store.state.email =  ''
            store.state.usd =  ''
            store.state.aud =  ''

            this.$cookies.remove("loggedIn")
            this.$cookies.remove("role")
            this.$cookies.remove("changed")

            this.$router.push('/')
        }
    }
}
</script>
