import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/home',
      name: 'Home',
      component: Home
    },
    {
      path: '/',
      name: 'login',
      component: () => import('../views/Login.vue'),
      meta: {layout: 'empty'}
    },
    {
      path: '/setpassword',
      name: 'setpassword',
      component: () => import('../views/Setpassword.vue'),
      meta: {
        layout: 'empty',
        requiresAuth: true
      }
    },
    {
      path: '/result',
      name: 'search result',
      component: () => import('../views/Result.vue'),
      meta: {
        layout: 'main',
        loggedIn: true
      }
    },
    {
      path: '/search',
      name: 'search form',
      component: () => import('../views/Search.vue'),
      meta: {
        layout: 'search',
        loggedIn: true
      }
    },
    {
      path: '/table',
      name: 'table form',
      component: () => import('../views/Table.vue'),
      meta: {
        layout: 'main',
        loggedIn: true,
        roleTable: true,
      }
    },
    {
      path: '/average',
      name: 'average form',
      component: () => import('../views/Average.vue'),
      meta: {
        layout: 'main',
        loggedIn: true
      }
    },
    {
      path: '/average-m',
      name: 'average form',
      component: () => import('../views/AverageMetal.vue'),
      meta: {
        layout: 'main',
        loggedIn: true
      }
    },
    {
      path: '/insert',
      name: 'insert form',
      component: () => import('../views/Insert.vue'),
      meta: {
        layout: 'insert',
        loggedIn: true,
        roleInsert: true
      }
    },
    {
      path: '/aus',
      name: 'australia form',
      component: () => import('../views/Australia.vue'),
      meta: {
        layout: 'insert',
        loggedIn: true,
        roleAus: true
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard main',
      component: () => import('../views/dashboard/Main.vue'),
      meta: {
        layout: 'dashboard',
        loggedIn: true,
        admin: true
      }
    },
    {
      path: '/dashboard/catalytic',
      name: 'dashboard catalytic',
      component: () => import('../views/dashboard/Catalytic.vue'),
      meta: {
        layout: 'dashboard',
        loggedIn: true,
        admin: true
      }
    },
    {
      path: '/dashboard/users',
      name: 'dashboard users',
      component: () => import('../views/dashboard/Users.vue'),
      meta: {
        layout: 'dashboard',
        loggedIn: true,
        admin: true
      }
    },
    {
      path: '/dashboard/insert',
      name: 'dashboard insert',
      component: () => import('../views/dashboard/Insert.vue'),
      meta: {
        layout: 'dashboard',
        loggedIn: true,
        admin: true
      }
    },
    {
      path: '/dashboard/newuser',
      name: 'dashboard newuser',
      component: () => import('../views/dashboard/NewUser.vue'),
      meta: {
        layout: 'dashboard',
        loggedIn: true,
        admin: true
      }
    },
    {
      path: '/dashboard/marks',
      name: 'dashboard marks',
      component: () => import('../views/dashboard/Marks.vue'),
      meta: {
        layout: 'dashboard',
        loggedIn: true,
        admin: true
      }
    },
    {
      path: '/dashboard/models',
      name: 'dashboard models',
      component: () => import('../views/dashboard/Models.vue'),
      meta: {
        layout: 'dashboard',
        loggedIn: true,
        admin: true
      }
    },
    {
      path: '/dashboard/generations',
      name: 'dashboard generations',
      component: () => import('../views/dashboard/Generations.vue'),
      meta: {
        layout: 'dashboard',
        loggedIn: true,
        admin: true
      }
    },
    {
      path: '/dashboard/countries',
      name: 'dashboard countries',
      component: () => import('../views/dashboard/Countries.vue'),
      meta: {
        layout: 'dashboard',
        loggedIn: true,
        admin: true
      }
    },
    {
      path: '/dashboard/filials',
      name: 'dashboard filials',
      component: () => import('../views/dashboard/Filial.vue'),
      meta: {
        layout: 'dashboard',
        loggedIn: true,
        admin: true
      }
    },
    {
      path: '/dashboard/journal',
      name: 'dashboard journal',
      component: () => import('../views/dashboard/Journal.vue'),
      meta: {
        layout: 'dashboard',
        loggedIn: true,
        admin: true
      }
    },
    {
      path: '/dashboard/change',
      name: 'dashboard change',
      component: () => import('../views/dashboard/Change.vue'),
      meta: {
        layout: 'dashboard',
        loggedIn: true,
        admin: true
      }
    },
    {
      path: '/dashboard/audcurrencies',
      name: 'dashboard audcurrencies',
      component: () => import('../views/dashboard/AudCurrency.vue'),
      meta: {
        layout: 'dashboard',
        loggedIn: true,
        admin: true
      }
    },
    {
      path: '/dashboard/metalrates',
      name: 'dashboard metalrates',
      component: () => import('../views/dashboard/MetalRates.vue'),
      meta: {
        layout: 'dashboard',
        loggedIn: true,
        admin: true
      }
    },
    {
      path: '/change',
      name: 'change',
      component: () => import('../views/Change.vue'),
      meta: {
        layout: 'insert',
        loggedIn: true,
        roleInsert: true
      }
    }
  ]
});
