<template>
<div>
    <div class="wrap uk-background-norepeat uk-background-cover uk-background-center-center uk-cover-container" style="background-color: #f8f8f8;">
        <div class="uk-flex uk-flex-center uk-height-viewport uk-position-z-index uk-position-relative">
            <!--HEADER-->
            <header id="site-head">
                <Navbar/>
            </header>
            <!--/HEADER-->

            <!--PAGE-->
            <router-view/>
            <!--/PAGE-->

            <!--FOOTER-->
            <Footer/>
            <!--/FOOTER-->
        </div>
    </div>
</div>
</template>

<script>
import Navbar from '@/components/app/Navbar'
import Footer from '@/components/app/Footer'

export default {
    name: 'main-layout',
    components:{
        Navbar, Footer
    }
}
</script>
