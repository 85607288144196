<template>
<div>
    <div class="wrap uk-background-norepeat uk-background-cover uk-background-center-center uk-cover-container" style="background-color: #f8f8f8;">

        <div id="banner" class="uk-cover-container uk-border-rounded" style="margin-top:55px; height:400px">
					<img :src="require('@/assets/banner.jpg')" alt="Alt img" data-uk-cover>
        </div>
        <div class="uk-flex uk-flex-center uk-height-viewport uk-position-z-index uk-position-relative">
            <!--HEADER-->
            <header id="site-head">
                <Navbar/>
            </header>
            <!--/HEADER-->

            <!--PAGE-->
            <router-view/>
            <!--/PAGE-->

            <!--FOOTER-->
            <Footer/>
            <!--/FOOTER-->
        </div>
    </div>
</div>
</template>

<script>
import Navbar from '@/components/app/Navbar'
import Footer from '@/components/app/Footer'

export default {
    name: 'main-layout',
    components:{
        Navbar, Footer
    }
}
</script>

<style scoped>
@media screen and (max-width: 960px){
	#banner{
       /* margin-top:73px; */

        background-size: cover;

	}
}
@media screen and (min-width: 961px){
	#banner{
       /* margin-top:35px; */

        background-size: contain;
	}
}
#banner{
        background-repeat: no-repeat;
        background-position: 50% 50%;
}
</style>
