<template>
    <div class="uk-container uk-container-expand">
        <div class="uk-grid uk-grid-medium uk-flex uk-flex-middle" data-uk-grid>
            <div class="uk-width-auto">
                <router-link tag='a' to="/" class="uk-logo" title=""><img :src=" locale === 'ru' ? require('@/assets/logo.png') : require('@/assets/logo_us.png')" style="width: 200px;" alt=""></router-link>
            </div>
            <!--
            <div class="uk-width-expand">
                <form id="search-form" action="">
                    <div class="uk-inline uk-width-1-1">
                        <span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: search"></span>
                        <input class="uk-input uk-width-1-1 search-fld" type="text" placeholder="Поиск">
                        <a href="#" class="search-filter" hidden>Поиск</a>
                    </div>
                </form>
            </div>
            -->
            <div class="uk-width-auto uk-navbar-right">
                <ul class="uk-subnav" data-uk-margin>
                    <li class="uk-visible@s" :uk-tooltip="'title:' + localize('Home') +'; pos: bottom'">
                        <router-link tag='a' to="search" data-uk-icon="icon: home"></router-link>
                    </li>
                    <li class="uk-visible@s" :uk-tooltip="'title:' + localize('New_cat') +'; pos: bottom'" v-if="['1','4'].includes(this.$data.role)">
                        <router-link tag='a' to="insert" data-uk-icon="icon: plus"></router-link>
                    </li>
                    <li class="uk-visible@s" v-if="['1','4','5'].includes(this.$data.role)">
                        <router-link tag='a' to="table" data-uk-icon="icon: album" :uk-tooltip="'title:' + localize('Full_catalog') +'; pos: bottom'"></router-link>
                    </li>
                    <li class="uk-visible@s">
                        <router-link tag='a' to="average" data-uk-icon="icon: cart" :uk-tooltip="'title:' + localize('TitleAverage') +'; pos: bottom'"></router-link>
                    </li>
                    <li class="uk-visible@s">
                        <router-link tag='a' to="average-m" data-uk-icon="icon: bolt" :uk-tooltip="'title:' + localize('TitleAverageM') +'; pos: bottom'"></router-link>
                    </li>
                    <li class="uk-visible@s"  v-if="this.$data.role === '5'">
                        <router-link tag='a' to="aus" data-uk-icon="icon: credit-card" :uk-tooltip="'title:' + localize('AudChange') +'; pos: bottom'"></router-link>
                    </li>
                    <li class="uk-visible@s"  v-if="['1','3','4','5'].includes(this.$data.role)">
                        <a href="#" data-uk-icon="icon:more" :uk-tooltip="'title:' + localize('Settings') +'; pos: bottom'"></a>
                        <div class="uk-navbar-dropdown uk-navbar-dropdown" data-uk-dropdown="mode: click; offset: 20;animation: uk-animation-slide-bottom-small; duration: 150">
                            <div class="uk-dropdown-grid">
                                <userSettings/>
                            </div>
                        </div>
                    </li>
                    <li @click="changeLocale()">
                        <a :style='{ backgroundImage: "url("+ require("@/assets/" + locale + ".png")+")"}' class="uk-icon uk-icon-image" :uk-tooltip="'title:' + localize('Language') +'; pos: bottom'"></a>
                    </li>
                    <li class="uk-visible@s" v-if="this.$data.role === '1'">
                        <router-link tag='a' to="dashboard" data-uk-icon="icon: laptop" :uk-tooltip="'title:' + localize('Dashboard') +'; pos: bottom'"></router-link>
                    </li>
<!--
                    <li class="uk-visible@s" @click="logout()">
                        <a data-uk-icon="icon: sign-out" :uk-tooltip="'title:' + localize('Logout') +'; pos: bottom'"></a>
                    </li>
-->
                    <li class="uk-visible@s">
                        <a data-uk-icon="icon: question" :uk-tooltip="'title:' + localize('FAQ') +'; pos: bottom'" href="/faq.pdf"></a>
                    </li>
                    <li class="uk-hidden@s">
                        <a data-uk-toggle href="#offcanvas-filter" data-uk-icon="icon: settings"></a>
                    </li>
                    <li class="uk-hidden@s">
                        <a data-uk-navbar-toggle-icon data-uk-toggle href="#offcanvas-nav"></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import userSettings from './UserSettings'
import localizeFilter from '@/filter/localize.filter'
import store from '@/store'
export default {
    data: () => ({
        locale: localStorage.getItem('locale') || 'ru',
        role: $cookies.get('role')
    }),
    components:{
        userSettings
    },
    methods: {
        changeLocale(){
	        store.state.locale === 'us' ? store.state.locale='ru' : store.state.locale = 'us'

            this.locale === 'us' ? this.locale='ru': this.locale = 'us'
			localStorage.setItem('locale', this.locale)
        },

        localize(key){
            return localizeFilter(key)
        },
         logout(){
            localStorage.removeItem('Pt')
            localStorage.removeItem('Pd')
            localStorage.removeItem('Rh')
            localStorage.removeItem('price')
            localStorage.removeItem('usd')
            localStorage.removeItem('aud')
            localStorage.removeItem('cotirovki')
            localStorage.removeItem('userSettings')
            localStorage.removeItem('login')

            store.state.loggedIn = ''
            store.state.login = ''
            store.state.userSettings =  ''
            store.state.Pt = ''
            store.state.Pd =  ''
            store.state.Rh =  ''
            store.state.price =  ''
            store.state.role =  ''
            store.state.email =  ''
            store.state.usd =  ''
            store.state.aud =  ''

            this.$cookies.remove("loggedIn")
            this.$cookies.remove("role")
            this.$cookies.remove("changed")

            this.$router.push('/')
        }
    }
}
</script>
