import localizeFilter from '@/filter/localize.filter'
export default function  getModel(value){
    //console.log(this.$data.selectedMark)
    this.$data.generations = []
    this.$data.models = []
    this.$data.selectedModel = ''
    this.$data.selectedGeneration = ''
    this.$Progress.start()
    axios.get('/filter.php', 
        {
            params: 
            {
                type: 2,
                selected: value
            }
        })
        .then(response => {
            if(response.data != -10){
                this.$data.models = response.data;
                this.$data.allmodels = response.data;
            }else
                UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
            this.$Progress.finish()
        })
        .catch(error =>{
            console.log(error)
            UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
            this.$Progress.fail()
        })
}